:root {
  --background: #18171b;
  --navbar-width: 22vw;
  --navbar-width-min: 80px;
  --navbar-dark-primary: #25272d;
  --navbar-dark-secondary: black;
  --navbar-light-primary: white;
  --navbar-light-secondary: white;
  --nav-text: #ec008bde;
  --icon-color: #bf2f82c4;
  font-family: Helvetica !important;
  --text-white: white;
  font-family: Helvetica;
}
body {
  background-color: var(--background);
}

.scroll {
  overflow-y: scroll;
  height: 100%;
}
.scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 99px;
  background-color: var(--navbar-dark-primary);
}
.scroll::-webkit-scrollbar-button {
  height: 1px;
}

@media only screen and (max-width:999px) {
  #miniNav {
    display: block !important;
  }
  #title {
    margin-top: 0.5rem;
    justify-content: space-evenly !important;
  }
  .inputHeadings {
    display: none !important;
  }
  #top-cont{
    justify-content: space-around;
  }
  #top-cont h2{
    font-size:1.8rem;
  }

  #top-cont .icon-button{
    height: 2.5rem;
    width: 2.5rem;
  }
  .btn {
   padding:0.5rem !important;
  }
  .t-head{
    font-size: small;
  }
  .inputField{
    width: 90% !important;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
}
.inputField{
  width: 45%;
  margin: 0.2rem;
 
}
#miniNav {
  display: none;
}
#title {
  justify-content: space-between;
}
#searchfield {
  display: block;
}



@media only screen and (max-width:426px){
  #top-cont h2{
    font-size:1.5rem;
  }

}